import * as React from "react"
import * as THREE from 'three';
import Layout from "../components/Layout";
import { Loading } from "../components/Loading";

import '../styles/index.scss';

import profile from '../images/profile.jpg'
import github from '../images/github.png'
import linkedin from '../images/linkedin.png'
// import mail from '../images/mail.png'
import { Link } from "gatsby";

const ContactPage = () => {
  return (
    <Layout>
      <nav style={{ margin: '3em auto 0 auto' }}>
          <Link to='/'>Main</Link>
          <Link to='/about'>About</Link>
          <Link to='/work'>Work</Link>
      </nav>
        <main>
            <header>
                <h1>Contact</h1>
            </header>

            <article className="me-info">
                <a href="mailto:admin@bartryb.pl"><img src={profile} alt="Avatar"/></a>
                <a target="_blank" href="https://github.com/bartfish"><img src={github} alt="Avatar"/></a>
                <a target="_blank" href="https://www.linkedin.com/in/bartlomiej-ryba/"><img src={linkedin} alt="Avatar"/></a>
            </article>
        </main>
    </Layout>
  )

}

export default ContactPage
